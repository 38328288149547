.Header_For_form {
  width: 100%;
}
.Header_Form_Btn .p-button {
  background-color: #fc4a1a !important;
  color: white !important;
  padding-top: 4px;
}
.Header_Form_Btn .pi {
  padding-right: 4px;
}
.Header_Form_Btn .p-button-label {
  color: white !important;
  padding-bottom: 3px !important;
}
.Header_Form_Employee {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 25px;
}
.Header_Form_Image {
  width: 15%;
}
.Header_Form_Text {
  width: 70%;
  text-align: center;
}
.Header_Form_Btn {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.caprus_image {
  height: 120px;
}
.logoutbutton {
  border: 0px;
  color: snow;
  width: 90px;
  height: 35px;
  background-color: #5666a1;
  cursor: pointer;
}
.image_capv {
  width: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.DeclarationFormText {
  font-size: 25px;
  color: #414040;
  margin: 0;
}
.Header_Form_Text_Mobile {
  display: none;
}
@media screen and (min-width: 200px) and (max-width: 380px) {
  .Header_Form_Text {
    display: none;
  }
  .Header_Form_Employee {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .Header_Form_Text_Mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .Header_Form_Btn {
    width: 100%;
  }
  .Header_Form_Employee {
    padding: 13px 25px;
  }
  .CaprusImage_Employee {
    width: 160px;
  }
  .DeclarationFormText {
    font-size: 20px;
  }
  .Instructions_Form {
    font-size: 13px;
  }
}
@media screen and (min-width: 380px) and (max-width: 480px) {
  .Header_Form_Text {
    display: none;
  }
  .Header_Form_Employee {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .DeclarationFormText {
    font-size: 20px;
  }
  .Instructions_Form {
    font-size: 13px;
  }
  .Header_Form_Text_Mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .Header_Form_Btn {
    width: 100%;
  }
  .Header_Form_Employee {
    padding: 13px 25px;
  }
}
@media screen and (min-width: 480px) and (max-width: 640px) {
  .Header_Form_Text {
    display: none;
  }
  .DeclarationFormText {
    font-size: 20px;
  }
  .Instructions_Form {
    font-size: 13px;
  }
  .Header_Form_Employee {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .Header_Form_Text_Mobile {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .Header_Form_Btn {
    width: 100%;
  }
  .Header_Form_Employee {
    padding: 13px 25px;
  }
}
/* @media screen and (min-width: 200px) and (max-width: 320px) {
  .Form900 {
    margin-left: -46px;
    font-size: 15px;
  }
  .Form911 {
    font-size: 13px;
  }
  .strong {
    font-size: 18px;
  }

  .image22kk {
    max-width: 123px;
    max-height: 80px;
  }
} */
/* @media screen and (min-width: 320px) and (max-width: 480px) {
  .Form900 {
    margin-left: -5px;
    font-size: 15px;
  }
  .Form911 {
    font-size: 13px;
  }
  .strong {
    font-size: 18px;
  }
  .controller {
    margin-top: -6px;
  }

  .image22kk {
    max-width: 123px;
    max-height: 80px;
    margin-top: 17px;
  }
}
@media screen and (min-width: 480px) and (max-width: 640px) {
  .Form900 {
    margin-left: -5px;
    font-size: 18px;
  }
  .controller {
    margin-top: -6px;
  }
  .Form911 {
    font-size: 13px;
  }
  .strong {
    font-size: 18px;
  }
  .image22kk {
    max-width: 123px;
    max-height: 80px;
    margin-top: 17px;
  }
}
@media screen and (min-width: 640px) and (max-width: 980px) {
  .Form900 {
    margin-left: -5px;
    font-size: 18px;
  }

  .Form911 {
    font-size: 13px;
  }
  .strong {
    font-size: 18px;
  }

  .image22kk {
    max-width: 123px;
    max-height: 80px;
  }
}
@media screen and (min-width: 980px) and (max-width: 1266px) {
  .Form900 {
    margin-left: -5px;
    font-size: 18px;
  }
  .Form911 {
    font-size: 13px;
  }
  .strong {
    font-size: 18px;
  }

  .image22kk {
    max-width: 123px;
    max-height: 80px;
  }
}
@media screen and (min-width: 1266px) and (max-width: 1346px) {
  .Form900 {
    margin-left: -5px;
    font-size: 18px;
  }
  .Form911 {
    font-size: 13px;
  }
  .strong {
    font-size: 18px;
  }

  .image22kk {
    max-width: 123px;
    max-height: 80px;
  }
}
@media screen and (min-width: 1346px) and (max-width: 1980px) {
  .Form900 {
    margin-left: -5px;
    font-size: 20px;
  }
  .Form911 {
    font-size: 16px;
  }
  .strong {
    font-size: 18px;
  }
  .image22kk {
    max-width: 170px;
    max-height: 110px;
  }
}
@media screen and (min-width: 1980px) and (max-width: 2700px) {
  .Form900 {
    margin-left: -5px;
    font-size: 21px;
  }
  .Form911 {
    font-size: 21px;
  }
  .strong {
    font-size: 19px;
  }
  .image22kk {
    max-width: 123px;
    max-height: 80px;
  }
} */
