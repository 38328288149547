.overrider {
  width: 100%;
  display: flex;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
  align-items: "center";
}
.loadingImage {
  position: absolute;
  top: 35%;
  z-index: 25;
}
@media screen and (min-width: 0px) and (max-width: 320px) {
}
@media screen and (min-width: 320px) and (max-width: 480px) {
}
@media screen and (min-width: 480px) and (max-width: 640px) {
}
@media screen and (min-width: 640px) and (max-width: 980px) {
}
@media screen and (min-width: 980px) and (max-width: 1266px) {
}
@media screen and (min-width: 1266px) and (max-width: 1346px) {
}
@media screen and (min-width: 1346px) and (max-width: 1980px) {
}
