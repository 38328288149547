.Insurance_Header_text {
  width: 100%;
  height: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  color: #284caf;
}
.each_fiedname {
  width: 11%;
  color: #284caf;
  font-weight: 550;
  text-align: center;
  font-size: 14px;
  padding:10px;
  display: flex;
  justify-content: center;
}

.each_fiedname1 {
  width: 11%;
  color: #284caf;
  font-weight: 550;
  text-align: center;
  font-size: 15px;
  padding:10px;
  display: flex;
  justify-content: center;
}

.each_fiedname_sno {
  width: auto;
  margin-right: 80px;
  color: #3d2f2f;
  font-weight: 550;
  text-align: center;
  font-size: 14px;
}
.sno_value{
  font-size: 17px;
  color: white;
  font-weight: 650;
}
.each_fiedname_disabled {
  width: 11%;
  color: #284caf;
  font-weight: 550;
  text-align: center;
  font-size: 14px;
  padding:10px;
  display: flex;
  justify-content: center;
}
.insurance_input_ {
  background-color: white;
  height: 26px;
  width: 140px;
  text-align: center;
  border: 0px;
}

.insurance_input_1 {
  background-color: white;
  height: 30px;
  width: 300px;
  text-align: center;
  border: 0px;
  
}
.Insurance_Fields {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 0px;
  background-color: transparent;
  border: 1px solid #284caf;
  border-radius:4px;
  
}

.Insurance_Fields1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  padding: 20px 23%;
  background-color: rgb(154, 149, 149);
}
.insurance_select {
  border: 0px;
  text-align: center;
  height: 26px;
  width: 140px;
}
.btns_add_sub {
  width: 23%;
  display: flex;
}

.btns_add_sub1 {
  width: auto;
  display: flex;
  
}

.input_modules {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.plus {
  width: 30px;
  height: 30px;
  background-color: green;
  margin-left: 20px;
  color: white;
  border-radius: 10px;
  border: 0px;
  cursor: pointer;
  font-size: 22px;
}
.plusTooltip .addMore {
  right: 0px;
}

.plusTooltip .addMore,
.minusTooltip .reduceMore {
  width: 120px;
  background-color: #284caf;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -35px;
  height: 30px;
  visibility: hidden;
}
.minuswithtooltip:hover .minusTooltip .reduceMore {
  visibility: visible;
}

.pluswithtooltip:hover .plusTooltip .addMore {
  visibility: visible;
}

.negative {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: #df5151;
  margin-left: 20px;
  color: white;
  border: 0px;
  cursor: pointer;
  font-size: 22px;
}
.insurance_ForDesktop {
  display: flex;
  flex-direction: column;
  padding: 17px;
  border: 1px solid #284caf;
  
}
.insurance_ForDesktop_Mobile {
  display: none;
}

@media screen and (max-width: 480px) {
  .btns_add_sub_Mobile {
    width: 100%;
    margin-top: 10px;
    display: flex !important;
    margin-right: 2%;
    justify-content: center !important;
  }
  .EDITVIEW {
    width: 100% !important;
  }
}
@media screen and (min-width: 480px) and (max-width: 640px) {
  .EDITVIEW {
    width: 35% !important;
  }
}
@media screen and (max-width: 1146px) {
  .Insurance_Fields {
    display: flex;
    flex-wrap: wrap;
  }

  
  .EDITVIEW {
    width: 60%;
  }
  .insurance_ForDesktop {
    display: none;
  }
  .insurance_ForDesktop_Mobile {
    display: block;
  }
  .each_fiedname_mobileView {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    align-items: center;
  }
  .Input_date_mobileView {
    border: 0px;
    text-align: end;
    height: 26px;
    width: 140px;
  }
  .each_field_label_Mobile {
    width: 50%;
    text-align: end;
    padding-right: 10px;
  }
  .each_field_inputField_Mobile {
    width: 50%;
    display: flex;
    justify-content: flex-start;
  }
  .btns_add_sub_Mobile {
    width: 100%;
    display: flex;
    margin-right: 2%;
    align-items: center;
    justify-content: flex-end;
  }
}
