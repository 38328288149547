.previouslyUploadedDocx {
  width: 100%;
  height: 100%;
  background-color: #d8d5d566;
  padding: 20px;
  display: flex;
  align-items: center;
}
.previouslyUploadedDocx_filename {
  width: 40%;
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
}
.previouslyUploadedDocx_file {
  width: 20%;
  display: flex;
}
.previouslyUploadedDocx_fileDownload {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
}
.previouslyUploadedDocx_fileChange .p-button {
  padding: 3px !important;
}
