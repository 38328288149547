.body {
  width: 100%;
  height: 100%;
}
.Parent_forBody {
  width: 100%;
  display: flex;
}
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.loader-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-content: center; */
  align-items: center;
  background-color: rgba(15, 15, 15, 0.5);
  border-radius: 5px;
  height: 200px;
  width: 200px;
  box-shadow: 1px 1px 1px 0px darkslategrey;
}

.loader {
  border: 1px solid white;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  width: 80px;
  height: 80px;
  animation-name: loading;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.loader-text {
  margin-top: 10px;
  padding-top: 10px;
  color: lightgrey;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  animation-name: fading;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.description-box {
  margin-top: 10px;
  padding: 10px 5px 10px 5px;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  height: 35px;
  line-height: 35px;
  width: 500px;
  background-color: #fff;
  text-align: center;
  border-radius: 5px;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fading {
  0%,
  100% {
    opacity: 0.05;
  }
  50% {
    opacity: 0.95;
  }
}
.personal_Info_Error_Mobile_Admin {
  display: none;
}
.personal_Info_Error_Admin {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.personal_Info_input_Admin {
  display: flex;
  flex-direction: column;
}
.personal_Info_input_480 {
  display: none;
}
.personal_Info_label_Admin {
  width: 20%;
  margin-left: 20%;
  font-weight: 600;
  text-align: justify;
  padding-right: 10px;
}

.personal_Info_input .p-dropdown {
  width: 213px !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #5d89d9;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #6c757d;
  border-left: 1px solid #cdcdcd;
  width: 2.357rem;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.personal_Info_label_Admin_Colon {
  width: 10%;
  text-align: center;
}
.personal_Info_label_input_Admin {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}
/* header */

.headerPart_forAdimn {
  display: flex;
  justify-content: space-between;
  background: #e8eae9;
  width: 100%;
  height: 80px;
  align-items: center;
}
.capp1 {
  height: 85px;
  width: 130px;
}
.capp2 {
  background-color: #5666a1;
  height: 31px;
  cursor: pointer;
  border: 0;
  margin-right: 2%;
  transition: 0.3s;
  color: white;
}
.capp2:hover {
  background-color: white;
  color: #284caf;
}
.signOut_Btn {
  margin-right: 2%;
}
.signOut_Btn .p-button-label {
  color: #4f4c4c;
}
.image_capv {
  width: 200px;
  padding-left: 10px;
}
/* header Completed */
/* sidebar */
.placeholdersearch {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}
.Employee_userNames {
  width: 225px;
  display: flex;
  flex-direction: column;
}

.Nodataforemployees {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Employee_userNames_mobileView {
  display: none;
}
.mail {
  height: 38px;
  margin: 0;
  font-size: 21px;
  align-items: center;
  text-align: center;
  background-color: #264653;
  color: white;
  display: flex;
  justify-content: center;
}
.p-text-center {
  text-align: center;
}
.capp6 {
  padding-top: 5px;
  background-color: #e1e1e1;
  /* height: 80vh;
  overflow-y: scroll; */
}
/* .capp6 ul::-webkit-scrollbar {
  display: none;
} */
.capp6 ul {
  margin: 0px;
  text-decoration: none;
  max-height: 75vh;
  cursor: pointer;
  
  margin-top: 10px;
  list-style: none;
  padding-left: 0px;
  width: 100%;
  display: inline-grid;
  color: #ce3131;
}
.capp6 ul li {
  border-bottom: 1px;
  list-style: none;
  padding: 5px;
  font-size: 17px;
  
  font-weight: 600;
  color: #727070;
  border-bottom: 1px solid #d3cdcd;
  margin: 0px;
  padding-left: 18px;
}
.capp6 ul li:hover {
  background-color: #ee8888;
  transition: 0.4s;
  color: white !important;
}
.capp6 ul::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.capp6 ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.capp6 ul::-webkit-scrollbar-thumb {
  /* //karthik */
  background: #000000;
  border-radius: 10px;
}

/* Handle on hover */
.capp6 ul::-webkit-scrollbar-thumb:hover {
  background: #b35700;
}
.signOut_Btn .p-button {
  background-color: #fc4a1a !important;
  color: white !important;
  padding-top: 4px;
  border-radius: 10px;
}
.signOut_Btn .pi {
  padding-right: 7px;
  font-size: 13px;
}
.signOut_Btn .p-button-label {
  color: white !important;
  padding-bottom: 3px !important;
}
.capp8 ul li {
  width: 100%;
  margin-top: 20px;
  clear: both;
}
.capp8 ul {
  text-decoration: none;
  cursor: pointer;
  list-style: none;
  color: #284caf;
}
.liactive {
  /* //karthik */
  background-color: #ce3131 !important;
  color: white !important;
}
/* sidebar ended */
/* body */
.employee_body {
  display: block;
  width: 70%;
}
.employee_switchingBtns {
  display: flex;
  justify-content: center;
}
.buttonactive {
  color: white;
  background-color: #fc4a1a;
  float: left;
  font-weight: 550;
  margin: 10px;
  border-radius: 10px;
  height: 39px;
  transition: 0.7s;
  border: none;
  width: 150px;
  cursor: pointer;
  border: 0px;
  outline: none;
}
.button {
  float: left;
  margin: 10px;
  font-size: 14px;
  width: 150px;
  transition: 0.7s;
  border-radius: 10px;
  border: 0;
  background-color: #264653;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  outline: none;
  color: white;
  height: 40px;
}
/* employedetails */
.capp8 {
  margin-top: 50px;
}
.Employee_Details_ {
  width: 100%;
  display: flex;
}
.empdetailsinadminHeading {
  width: 100%;
  text-align: center;
}
.empdetailsinadmin {
  font-weight: 700;
  font-size: 15px;
  margin-left: 42%;
}
.empdetailsinadmin_D {
  width: 45%;
  text-align: start;
  padding-left: 10%;
}
.employee_footer_uploadExcel_1266 {
  display: none;
}
.colonalignment {
  width: 10%;
  text-align: center;
  align-items: center;
}
.empdetailsinadmin_H {
  width: 45%;
}
.p-c {
  color: white;
}
/* status */
.Status_selection {
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  display: flex;
}
.Status_selection_sendforVerification {
  width: 100%;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Status_selection_Label {
  width: 100%;
  margin-top: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.Finance_Heading,
.activity_Heading {
  width: 100%;
  display: flex;
  align-items: center;
}
.Finance_Heading_ {
  width: 100%;
  display: flex;
}
.Finance_Heading_sNo,
.Activity_Heading_sNo {
  width: 10%;
  height: 30px;
  border: 1px solid #d7d7d7;
  text-align: center;
}
.Finance_Heading_Data,
.Activity_Heading_Data {
  width: 30%;
  border: 1px solid #d7d7d7;
  height: 30px;
  text-align: center;
}

.pending_status_data {
  margin-left: 5px;
  margin-right: 20px;
}
.Finance_Heading_sNo_H {
  width: 10%;
  padding-top: 10px;
  height: 30px;
  text-align: center;
}

.Finance_Heading_Data_H {
  width: 30%;
  height: 30px;
  padding-top: 10px;
  text-align: center;
}
.finance_H_Weight {
  font-weight: 600;
}
.finance_Table,
.activity_Table {
  border: 1px solid #cbcbcb;
  padding-bottom: 15px;
}

.Status_selection_sendforVerification textarea {
  margin-left: 10px;
}
.updatedStatus_successfully {
  color: green;
}
.buttonempcodeSubmit {
  background-color: #fc4a1a;
  width: 124px;
  box-shadow: 3px 5px 7px 0px rgb(197 179 179 / 94%),
    1px 0px 20px 0px rgb(6 0 0 / 19%);
  cursor: pointer;
  border: 1px solid #e6e6e6;
  color: white;
  height: 38px;
  border-radius: 10px;
}
.buttonempcodeSubmit1 {
  background-color: #fc4a1a;
  width: 124px;
  box-shadow: 3px 5px 7px 0px rgb(197 179 179 / 94%),
    1px 0px 20px 0px rgb(6 0 0 / 19%);
  cursor: pointer;
  border: 1px solid #e6e6e6;
  color: white;
  height: 38px;
  border-radius: 10px;
}
.selecthandler {
  width: 200px;
}
.selecthandler2 {
  width: 215px;
}

/* documents */
.FilesD {
  margin-left: 10px;
  font-size: 14px;
}
/* assign employeeCode */
.assign_employeeCodeStatus {
  width: 100%;
  display: flex;
  justify-content: center;
}
.empcodeassign2 {
  color: red;
  font-weight: 700;
  margin-top: 30px;
}
.Form55_Admin {
  background-color: #264653;
  color: white;
  margin: 20px 0px 0px 0px;
  padding: 5px 20px;
  font-size: 15px;
}

.NoData {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* body ended */
/* upload excel */
.employee_footer_uploadExcel {
  width: 13%;
  display: flex;
  height: 100%;
  margin-top: 10px;
  justify-content: flex-end;
  margin-right: 3px;
  cursor: pointer;
}
.employee_footer_uploadExcel .p-button {
  height: 40px;
  display: flex;
  align-items: center;
}
.Employee_userNames .pi-bars {
  display: none;
  /* top: 89px;
  position: absolute;
  font-size: 22px;
  color: white;

  left: 5px;
  cursor: pointer; */
}

/* upload excel ended */
/* signout */
.p-grid {
  display: flex;
  justify-content: space-around;
}
.p-col-6 {
  width: 90px;
}
.employee_switchingBtns_forMobile {
  display: none;
}
.p-dialog .p-dialog-footer .p-confirm-dialog-accept {
  background-color: #fc4a1a;
}

.p-dialog .p-dialog-footer .p-confirm-dialog-accept:hover {
  background-color: #af5112 !important;
}

.p-dialog .p-dialog-footer .p-confirm-dialog-reject {
  background-color: #2196f3;
}

.p-dialog .p-dialog-footer .p-confirm-dialog-reject:hover {
  background-color: #0f5c9b !important;
}
.EmployeeRelieved_Details {
  width: 100%;
  padding: 20px 45px;
  display: flex;
  flex-direction: column;
}
.EmpRelieved_Field_Value {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.EmpRelieved_Field_Value_mobile {
  display: none;
}
.EmpRelieved_Field {
  width: 15%;
  margin-bottom: 10px;
}
.Finance_Heading_Mobile {
  display: none;
}
.EmpRelieved_Field2 {
  width: 13%;
  margin-left: 22%;
  text-align: left;
  padding-right: 12px;
  margin-bottom: 10px;
}
.EmpRelieved_Field_input {
  width: 33%;
  margin-bottom: 10px;
}
.EmpRelieved_Field_input2 {
  width: 15%;
  margin-bottom: 10px;
}
.Input_fields_Admin {
  height: 25px;
  border: none;
  outline: none;
  text-align: center;
  background-color: #169393;
  color: white !important;
}
.Input_fields_Admin::placeholder {
  color: white;
}
.Input_fields_Admin_date {
  height: 25px;
  border: none;
  width: 166px;
  padding-left: 25px;
  outline: none;
  text-align: end;
  background-color: #169393;
  color: white;
  text-align: center;
}
.Input_fields_Admin_date::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
/* for dialog box */
.employee_footer_uploadExcel .p-button {
  margin: 0 0.5rem 0 0;
  min-width: 10rem;
}

.employee_footer_uploadExcel p {
  margin: 0;
  line-height: 1.5;
}

.employee_footer_uploadExcel .p-dialog .p-button {
  min-width: 6rem;
}

.sideClosed {
  display: none;
}

@media screen and (max-width: 480px) {
  .EmployeeRelieved_Details {
    width: 100%;
    padding: 20px 11px;
  }
  .EmpRelieved_Field_Mobile {
    width: 40% !important;
    margin-left: 0% !important;
  }
  .Finance_Heading_sNo_H_Mobile_label {
    width: 40% !important;
    margin-left: 0% !important;
    font-size: 14px;
    text-align: start;
  }
  .personal_Info_input_Admin {
    display: none;
  }
  .personal_Info_input_480 {
    display: flex;
    flex-direction: column;
  }

  .Input_fields_Admin {
    width: 100%;
  }
  .personal_Info_label_input_Admin .personal_Info_input {
    flex: 1;
  }
}

@media screen and (max-width: 340px) {
  .EmployeeRelieved_Details {
    width: 100%;
    padding: 20px 11px;
  }
  .p-toast {
    width: 17rem;
  }
  .capp8 {
    margin-top: 10px;
  }
  .headerPart_forAdimn {
    height: 50px;
  }
  .signOut_Btn .p-button {
    width: 85px;
    font-size: 10px;
  }
  .signOut_Btn .pi {
    font-size: 10px;
  }
  .image_capv {
    width: 100px;
  }

  .p-col-6 {
    width: 80px !important;
  }
  .employee_switchingBtns {
    display: none !important;
  }

  .capp6 .pi-search {
    padding-left: 6px;
    font-size: 16px !important;
  }
  .empdetailsinadminHeading {
    font-size: 12px;
    text-align: start;
  }

  .Employee_Details_ {
    width: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .capp6 ul li {
    font-size: 9px;
  }
  .empdetailsinadmin_H {
    width: 45%;
    text-align: start;
  }
  .empdetailsinadmin {
    font-weight: 700;
    font-size: 10px;
  }
  .empdetailsinadmin_D {
    width: 50%;
    text-align: none;
    padding-left: 10px;
    font-size: 10px;
  }
  .capp6 .p-inputtext {
    font-size: 16px;
  }
  .employee_body {
    overflow: hidden;
    width: 100%;
  }
  .Employee_userNames {
    height: 150px;
    width: 100%;
    overflow: auto;
  }
  .employee_switchingBtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .employee_footer_uploadExcel {
    display: none;
  }
  .employee_footer_uploadExcel_1266 {
    display: flex;
    width: 90%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .employee_switchingBtns_forMobile {
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 0;
    overflow-x: auto;
  }
  .button,
  .buttonactive {
    width: calc(100% - 35%);
    font-size: 9px;
    margin: 0px !important;
    border-radius: 0px !important;
  }
  .NoData {
    width: 100%;
    font-size: 14px;
    display: block;
    text-align: center;
  }
  .empcodeassign2 {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .p-toast {
    width: 20rem;
  }
  .personal_Info_label_Admin {
    width: 20%;
    margin-left: 10%;
    text-align: justify;
    padding-right: 10px;
    display: flex;
    justify-content: flex-start;
  }
  .buttonempcodeSubmit {
    margin-bottom: 170px !important;
  }
  .Parent_forBody {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .sideClosed {
    padding: 7px;
    display: flex;
    flex-direction: column;
  }

  .sideClosed .pi-bars {
    background-color: #9f2bf6;
    color: white;
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
  }
  .signOut_Btn .p-button {
    width: 85px;
    font-size: 10px;
    padding-top: 8px;
  }
  .signOut_Btn .pi {
    font-size: 10px;
  }
  .capp6 .pi-search {
    width: 13%;
    font-size: 16px !important;
  }
  .capp8 {
    margin-top: 12px;
  }
  .Employee_userNames_mobileView {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .Employee_userNames {
    display: none;
  }
  .Employee_userNames_mobileView .pi-bars {
    display: block;
    top: 58px;
    position: absolute;
    font-size: 22px;
    color: white;
    left: 5px;
    cursor: pointer;
  }
  .p-col-6 {
    width: 80px !important;
  }
  .employee_switchingBtns {
    display: none !important;
  }
  .image_capv {
    width: 130px;
  }
  .headerPart_forAdimn {
    height: 50px;
  }

  .empdetailsinadminHeading {
    color: rgb(3, 51, 228);
    font-weight: 700;
    font-size: 18px;
    border-bottom: 1px solid #d7d7d7;
    text-align: start;
  }

  .Employee_Details_ {
    width: 100%;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }
  .capp6 ul li {
    font-size: 14px;
  }
  .empdetailsinadmin_H {
    width: 45%;
    text-align: start;
  }
  .empdetailsinadmin {
    font-weight: 700;
    font-size: 13px;
    margin-left: 0px;
  }
  .empdetailsinadmin_D {
    width: 45%;
    text-align: none;
    padding-left: 0px;
    font-size: 13px;
  }
  .capp6 .p-input-icon-left {
    margin-top: 8px;
    width: 90%;
  }
  .capp6 .p-inputtext {
    width: 90%;
  }
  .employee_body {
    overflow: hidden;
    width: 100%;
  }

  .employee_switchingBtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .employee_footer_uploadExcel {
    display: none;
  }
  .employee_footer_uploadExcel_1266 {
    display: none;
  }
  .employee_switchingBtns_forMobile {
    display: flex;
    position: fixed;
    width: 100%;
    bottom: 0;
    overflow-x: auto;
    z-index: 1;
  }
  .button,
  .buttonactive {
    width: calc(100% - 1%);
    font-size: 12px;
    margin: 0px !important;
    border-radius: 0px !important;
  }
  .NoData {
    width: 100%;
    font-size: 14px;
    display: block;
    text-align: center;
  }
  .empcodeassign2 {
    text-align: center;
  }
  .uploadExcel_1266 {
    display: flex;
    width: 90%;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 480px) and (max-width: 640px) {
  .employee_body {
    overflow: hidden;
    width: 100%;
  }
  .personal_Info_Error_Mobile_Admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .personal_Info_Error_Admin {
    display: none;
  }
  .employee_switchingBtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .employee_footer_uploadExcel {
    display: none;
  }
  .employee_footer_uploadExcel_1266 {
    display: flex;
    width: 90%;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .uploadExcel_1266 {
    display: flex;
    width: 90%;
    justify-content: flex-end;
  }
  .EmployeeRelieved_Details {
    width: 100%;
    padding: 20px 11px;
  }
}

@media screen and (min-width: 640px) and (max-width: 980px) {
  .employee_switchingBtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .personal_Info_Error_Mobile_Admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .personal_Info_Error_Admin {
    display: none;
  }
  .personal_Info_label_Admin {
    width: 30%;
    margin-left: 20%;
    text-align: justify;
    padding-right: 10px;
  }
  .employee_footer_uploadExcel {
    display: none;
  }
  .EmpRelieved_Field2 {
    width: 30% !important;
    margin-left: 0% !important;
    text-align: left;
    padding-right: 12px;
    margin-bottom: 10px;
  }
  .employee_footer_uploadExcel_1266 {
    display: flex;
    width: 90%;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 980px) and (max-width: 1266px) {
  .employee_body {
    width: 100%;
  }
  .personal_Info_Error_Mobile_Admin {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }
  .personal_Info_Error_Admin {
    display: none;
  }
  .employee_switchingBtns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .employee_footer_uploadExcel {
    display: none;
  }
  .employee_footer_uploadExcel_1266 {
    display: flex;
    width: 90%;
    justify-content: flex-end;
  }
  .EmpRelieved_Field2 {
    width: 21% !important;
    margin-left: 10% !important;
    text-align: left;
    padding-right: 12px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1266px) and (max-width: 1346px) {
  .EmpRelieved_Field2 {
    width: 15%;
    margin-left: 20%;
    text-align: left;
    padding-right: 12px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1346px) and (max-width: 1980px) {
}
@media screen and (max-width: 1000px) {
  .EmpRelieved_Field_Value {
    display: none;
  }
  .buttonempcodeSubmit1 {
    margin-bottom: 100px;
  }
  .EmpRelieved_Field_Value_mobile {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
  }
  .EmpRelieved_Field_Mobile {
    width: 30%;
    margin-left: 10%;
  }
  .EmpRelieved_Field_input_Mobile {
    width: 40%;
  }
  .EmpRelieved_Field_Colon_Mobile {
    width: 10%;
    text-align: center;
  }
  .activity_Heading {
    display: none;
  }
  .activity_Table {
    display: none;
  }
  .activity_Table_Mobile {
    border: 1px solid #f4f4f4;
    padding-bottom: 15px;
  }
  .finance_Table,
  .activity_Table {
    border: 1px solid #f4f4f4;
    padding-bottom: 15px;
  }
  .Finance_Heading_Mobile {
    width: 100%;
    margin-top: 14px;
    display: flex;
    border-bottom: 1px solid #d7d7d7;
    flex-direction: column;
  }
  .MobileView_clearance_Form {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  .Finance_Heading_sNo_H_Mobile {
    width: 50%;
    display: flex;
  }
  .Finance_Heading_sNo_H_Mobile_label_Colon {
    width: 10%;
    text-align: center;
  }
  .Finance_Heading_sNo_H_Mobile_label {
    width: 35%;
    margin-left: 5%;
    font-size: 14px;
    text-align: start;
  }
  .Finance_Heading,
  .activity_Heading {
    display: none;
  }
  .Finance_Heading_ {
    display: none;
  }
}
