.Input_textArea {
  
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #284caf;
  width: 100%;
}

.personal_Info_label11 {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-left: 15px;
}

.personal_Info_fields_ExitInterview {
  width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    align-items: center;
    background-color: white;
    flex-wrap: wrap;
    display: flex;
    border: 1px solid #284caf;
 
}

.Principle_Reason {
  padding: 0px 10px;
  width: 100%;
}

.Input_textArea_1346 {
  display: none;
}
.tasks_Acceptance {
  width: 100%;
  margin: 20px 0px;
  height: 100%;
  align-items: center;
  display: flex;
}
.tasks_Acceptance_H {
  width: 100%;
  margin: 20px 0px;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.task_Name {
  width: 55%;
}

.multiple_checks {
  text-align: center;
  display: flex;
  justify-content: space-between;
  width: 25%;
}

.multiple_checks1{
  width: 100%;
  display: flex;
  justify-content: space-around;
}


.checkboxContainer {
  width: 45%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}
.multiple_checks_MobileView {
  display: none;
}
.multiple_checks_Mobile {
  display: none;
}
@media screen and (max-width: 480px) {
  .mobile_Checkbox {
    width: 10% !important;
    text-align: end;
  }
  .multiple_checks_Mobile {
    width: 90%;
    display: flex;
    margin-left: 2%;
    flex-direction: column;
    text-align: start;
  }
  .tasks_Acceptance {
    width: 100%;
    margin: 40px 0px;
    height: 100%;
    align-items: center;
    display: flex;
  }
  .Principle_Reason {
    margin: 40px 0px;
    
  }
}

@media screen and (max-width: 800px) {
  .Submit_btn_userpage_admin_otp {
    margin-bottom: 40px;
  }
  .EDITVIEW {
    width: 100% !important;
  }
  .Question {
    font-weight: 600;
    color: #284caf;
    font-size: 14px;
  }
  .multiple_checks_MobileView {
    width: 100%;
    color: #393939;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  .multiple_checks {
    display: none;
  }
  .leaving_Info_fields {
    display: block;
  }
  .tasks_Acceptance {
    display: block;
    margin: 40px 0px;
  }
  .tasks_Acceptance_H {
    display: none;
  }
  .task_Name {
    margin-bottom: 10px;
    width: 100%;
    font-weight: 400;
    color: #284caf;
  }
  .Principle_Reason {
    padding: 0px 10px;
  }
  .Q_A {
    padding: 20px 18px;
  }
  .mobile_Checkbox {
    width: 5%;
    text-align: end;
  }
  .multiple_checks_Mobile {
    width: 95%;
    display: flex;
    margin-left: 2%;
    flex-direction: column;
    text-align: start;
  }
}
@media screen and (max-width: 1346px) {
  .Input_textArea {
    display: none;
  }
  .Input_textArea_1346 {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    margin-bottom: 20px;
  }
}
