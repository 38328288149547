.menubuttonactive {
  cursor: pointer;
  background-color: white;
  float: right;
  margin-right: 20px;
  border-radius: 0px;
  font-weight: 700;
  font-size: 15px;
  border-top: 0;
  color: #264653;
  padding-bottom: 5px;
  border-right: 0;
  border-bottom: 3px solid #264653;
  border-left: 0;
}
.edit_view_btns_profile {
  width: 20%;
  display: flex;
}

.Input_date {
  background-color: white;
  color: #284caf;
}
.menubutton {
  border: 0px;
  color: #284caf;
  float: right;
  background-color: white;
  margin-right: 20px;
  cursor: pointer;
  font-size: 15px;
}
.EDITVIEW_Insurance_Profile_Pfdetails_BTNs {
  display: flex;
  width: 100%;
  justify-self: center;
  align-items: center;
}
.EDITVIEW {
  width: 20%;
}
.Insurance_Profile_Pfdetails_BTNs {
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Insurance_Profile_Pfdetails_BTNs_disabling_editView {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Insurance_Profile_Pfdetails_UploadedDocx_BTNs {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
  align-items: center;
}
.Insurance_Profile_Pfdetails_BTNs_forMobile {
  display: none;
}

.personal_Info .p-inputtextarea{
  margin-left: 15px;
  border-color: #284caf;
}


@media screen and (max-width: 480px) {
  .menubutton {
    font-size: 12px;
  }
  .menubuttonactive {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .Insurance_Profile_Pfdetails_BTNs {
    display: none;
  }
  .Insurance_Profile_Pfdetails_BTNs_disabling_editView {
    display: none;
  }
  .Insurance_Profile_Pfdetails_UploadedDocx_BTNs {
    display: none;
  }
  .EDITVIEW {
    width: 100%;
  }
  .Insurance_Profile_Pfdetails_BTNs_forMobile {
    width: 100%;
    display: flex;
    height: 30px;
    position: fixed;
    z-index: 100;
    bottom: 0;
  }
  .menubutton {
    width: 100%;
    font-size: 12px;
    margin-right: 0.5px;
    padding-bottom: 0px;
    color: white;
    background-color: #264653;
  }
  .menubuttonactive {
    width: 100%;
    padding-bottom: 0px;
    color: white;
    font-size: 12px;
    background-color: #fc4a1a;
    margin-right: 0.5px;
  }
}

@media screen and (max-width: 1346px) {
  .EDITVIEW {
    width: 30%;
  }
}
