body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
  position: relative;
}

::-webkit-scrollbar:horizontal {
  height: 0px;
  width: 100%;
  margin-bottom: 10px;

}

::-webkit-scrollbar-track {
  background-color: #f4d6ca;
  -webkit-box-shadow: inset 0 0 3px #f7e1aa;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #fc4a1a;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

@media (min-width: 768px) {
  ::-webkit-scrollbar {
    width: 8px;
  }
}