
.HRSign_Date {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 50px;
  align-items: center;
}

.personal_Info_fields{
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #284caf;
    border-radius: 10px;
    align-items: center;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
}

.personal_info_fields2{
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid #284caf;
  border-radius: 10px;
  align-items: flex-start;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
}

.Input_date, .Input_fields::-webkit-input-placeholder {
  font-size: 14px;  
}

.personal_Info_label_input{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  margin-bottom: 10px;
}

.input_field{
 width: 100%;
}

.personal_Info_label_input2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  margin-bottom: 10px;
  margin-right: 23px;
  padding: 18px;
}

.personal_Info_label_input3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  margin-right: 32px;
  padding: 20px 35px;  
}

.Input_fields_textArea{
  border-color: #284caf !important;
  max-width: "400px" !important;
  /* width: 100% !important; */
}
.Input_fields_textArea_disable {
  border-color: #d7d7d7 !important;
    max-width: "400px" !important;
    width: 100% !important;
}

.personal_Info_label_input1 {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  margin-bottom: 10px;
}

.Input_fields_textArea{
  width: 100%;
  border: 1px solid;
  border-radius: 10px;
  text-align: left;
}
.personal_Info_label1 {
  width: 17%;
  margin-left: 33%;
  text-align: justify;
  padding-right: 10px;
}
.personal_Info_input1{
  width: 20%;
}
.personal_Info_Error1{
  width: 30%;
}
.Error {
  font-size: 14px;
  color: red;
}

.Input_date {
  width: 100%;
  height: 40px;
}


.Input_date1{
  width: 300px;
  height: 31px;
  border-radius:10px;
  border: 1px solid #284caf;
  text-align: center;
}

.HRSign{
  width: 50%;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  font-size: 14px;
}

.HRDate {
  width: 50%;
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
}

.HR_label {
  display: flex;
  justify-content: flex-start;
}

.HRDate_label {
  display: flex;
  justify-content: flex-start;
  /* padding-right: 260px;
  padding-bottom: 10px; */
}

.personal_info_label111{
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.furthercorrespondence {
  display: flex;

  margin-top: 30px;
  margin-bottom: 16px;
  width: 100%;
}

.Heading_correspondence {
  margin-top: 20px;
  margin-bottom: 5px;
}
.Emp_exp_Here {
  width: 100%;
  display: block;
}
.Question {
  font-weight: 600;
  color: #284caf;
  font-size: 17px;
}
.QuestionDisabled {
  color: gray;
}

.Q_A {
  padding: 20px 0px;
}

.leaving_Info_fields {
  display: flex;
  padding: 10px;
  flex-wrap: wrap;
}

.leaving_Info_fields .p-field-checkbox {
  padding: 10px 15px;
}
.Principle_Reason {
  margin: 20px 0px;
}
.label_checkbox {
  margin-left: 10px;
}
.Input_fields_textArea {
  text-align: center;
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 480px) {
  .input_field{
    padding: 10px;
  }
  .furthercorrespondence {
    font-size: 13px;
  }

  .HR_label{
    width: 100%;
  }

  .Input_date1{
    width: 100%;
  }

  .input_field .personal_Info_label_input {
    padding: 0px;
    margin: 0px;
  }

  .signature_file_container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  h6{
    padding:20px;
    text-align: justify;
  }

  .personal_Info_label_input{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }

  .personal_Info_label_input2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 7px;
  }

  .Input_fields_textArea{
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    text-align: left;
  }

  .personal_Info_label_input3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px;
    padding: 20px;  
  }


}
@media screen and (max-width: 640px) {
  .Input_fields_textArea_640 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .HRSign_Date {
    display: block;
  }
  .HRSign,
  .HRDate {
    width: 100%;
    display: flex;
    justify-content: start;
    padding: 20px 0px 0px 10px;
    align-items: center;
  }
  .signature_forHr {
    margin-right: 80px;
    width: 0%;
    display: grid;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 1346px) {
  .personal_Info_Error_Text {
    display: none;
  }
  .personal_Info_Error_Mobile_text {
    margin-bottom: 10px;
    display: flex;
    margin-left: 65%;
    justify-content: flex-start;
  }
}
