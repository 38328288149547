.Body {
  width: 100%;
  height: 100%;
}
.personal_Info_label_Input_ppno {
  display: flex;
  padding: 0 2%;
  margin: 1%;
  width: 100%;
  /* width: 100%;
  max-width: 220px; */
  flex-direction: column;
}
.personal_Info {
  border-bottom: 0px solid #d7d7d7;
  margin: 10px 0 0 0;
  padding: 15px 25px;
  background-color: transparent
}

.personal_Info .Education_Inf0 .employment_Info {
  width: 100%;
  height: 100%;
}
.personal_Info_fields {
  width: 100%;
  height: 100%;
  padding: 20px;
  border: 1px solid #284caf;
  border-radius: 10px;
  
  background-color: white;
 
}
.employment_Info_fields {
  width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid #284caf;
    border-radius: 10px;
    margin-top: 20px;
    background-color: white;


}
.personal_Info_label .pi {
  font-size: 13px;
  border-radius: 10px;
  background: gray;
  color: white;
  align-items: center;
}

.personal_Info_label span {
  margin-left: 15px;
}
.employment_Info_fields {
  width: 100%;
  height: 100%;
  background-color:white;
  padding: 20px;
  margin-top: 10px;
  border-radius: 10px;
  

}
.personal_Info_label_input {
  display: flex;
  padding: 0 2%;
  margin: 1%;
  width: 100%;
  /* width: 100%;
  max-width: 220px; */
  flex-direction: column;
  
}
.personal_Info_label {
  text-align: justify;
 
  font-size: 14 !important;
}
.personal_Info_input {
 width: 100%;
}

.personal_Info_Error {
  width: 100%;
}
.Error {
  font-size: 14px;
  color: red;
}
.Input_fields {
  height: 40px;
  width: 100%;
margin-left: 15px;
  margin-top: 4px;
  border: 1px solid #284caf;
  border-radius: 10px;
  outline: none;
  transition: "0.3s ease";
  text-align: center;
}
.Input_fields_Disable {
    height: 40px;
      width: 100%;
      margin-left: 15px;
      margin-top: 4px;
      border: 1px solid #d7d7d7;
      border-radius: 10px;
      outline: none;
      transition: "0.3s ease";
      text-align: center;
}
.Input_fields_upload_doc {
  height: 40px;
  width: 100%;
margin-left: 15px;
  margin-top: 4px;
  border: none;
  outline: none;
  transition: "0.3s ease";
  text-align: center;
}
.Input_fields_textArea {
  border-color: #284caf !important;
  max-width: "400px" !important;
  /* width: 100% !important; */
}
.Input_fields:focus {
  border: 2px solid #fc4a1a;
  transition: "0.3s ease";
   
}
.Input_Select {
  height: 40px;
  width: 100%;
  margin-left: 15px;
  transition: "0.3s ease";
  margin-top: 4px;
  border: 1px solid #284caf;
  border-radius: 10px;
  outline: none;
  text-align: center;
  color: #284caf;
}
.Input_Select:focus {
  border: 2px solid orange;
  border-radius: 10px;
  transition: "0.3s ease";
  width: 100%;
  height: 40px;
}
.Input_date {
  height: 40px;
  width: 100%;
  margin-left: 15px;
  margin-top: 4px;
  border: 1px solid #284caf;
  border-radius: 10px;
  outline: none;
  transition: "0.3s ease";
  text-align: center;
  color: #284caf;
}
.Input_date:focus {
  border: 2px solid orange;
  border-radius: 10px;
  transition: "0.3s ease";
}
.Optional_Icon {
  display: inline;
  border: 0px;
  border-radius: 100%;
  background-color: rgb(133, 117, 117);
  color: white;
  width: 18px;
}
.experirnceSelect_ {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: 3%;
  align-items: center;
}
.addExpireance {
  border: 0px;
  height: 37px;
  border: 2px solid #d7d7d7;
  width: 150px;
  background-color: #fc4a1a;
  font-size: 15px;
  transition: "0.3s ease";
  color: rgb(252, 250, 250);
  cursor: pointer;
}
.addExpireance:focus {
    transition: "0.3s ease";
    border: 3px solid #d7d7d7;
}
.declaration_input {
  margin-left: 0px;
}

/* personnalInformation ended */
.useSameAddress_ {
  display: flex;
  margin: 15px 0px;
  width: 100%;
  align-items: center;
  color: white;
  justify-content: flex-start;
  padding: 10px 5px;
  background: linear-gradient(45deg, #424242, transparent);
}

.update_empDetailsBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}


.save-btn {
  margin-top: 153px;
}
.view-edit-btn:hover {
  background-color: rgb(175, 167, 167);
}

.view-edit-btn.active-btn,
.save-btn:hover {
  background-color: #5666a1;
  color: white;
}
.successfully_saved {
  display: flex;
  align-items: center;
  width: 100%;
  color: green;
  justify-content: center;
}
.Incorrect_data {
  display: flex;
  align-items: center;
  width: 100%;
  color: red;
  justify-content: center;
}
.addExpireienceBtns {
  width: 100%;
  padding-top: 15px;
  display: flex;
  padding-left: 3%;
  justify-content: center;
}
.addExpireienceBtn {
  border: 0px;
  height: 32px;
  background-color: #5666a1;
  color: white;
  margin: 0px 10px;
  cursor: pointer;
}
.strong {
  color: red;
}
.check {
  margin: 0;
  font-size: 18px;
}
.fonter {
  font-weight: 500;
  padding: 0xp 10px;
  font-size: 16px;
  margin: 0;
  padding: 10px;
}
.incorrect_data {
  text-align: center;
  color: red;
}
.Form55 {
  color: #3b3838;
  margin: 0px 0px;
  font-size: 26px;
}
.FooterJ10 {
  cursor: pointer;
  color: #ebe7ec;
  width: 122px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  padding: 9px;
  background-color: #5666a1;
}
.FooterJ10:hover {
  background-color: white;
  color: #284caf;
}
.Instructions {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Instructions_Form {
  font-size: large;
  font-family: inherit;
  width: 97%;
  padding-left: 15px;
  margin-top: 30px;
}
.personal_Info_Error_Mobile {
  display: none;
}
.Hereby_Accept {
  display: flex;
  align-items: center;
  width: 100%;
}
.LoadingCmp_ {
  width: 100%;
  display: flex;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 20;
  background: rgba(255, 255, 255, 0.5);
  justify-content: center;
}
.LoadingCmp_Image {
  position: absolute;
  top: 29%;
  z-index: 25;
}
/* alertsame address */
/* alertsame address ended */
@media screen and (max-width: 320px) {
  .personal_Info_fields {
    padding: 7px 0px;
  }

  .personal_Info_Error_Mobile {
    display: flex !important;
    justify-content: center !important;
  }
  .Error {
    font-size: 12px;
  }
}
@media screen and (max-width: 480px) {
  .Submit_btn_ {
    padding-bottom: 50px;
  }
  .personal_Info_fields {
    padding: 10px 0px;
  }

  .personal_Info_label {
    font-size: 15px;
  }
  .personal_Info_Error {
    display: none;
  }
  .personal_Info_Error_Mobile {
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    margin-left: 30%;
    justify-content: center;
  }
  .personal_Info_label_input {
    margin-bottom: 0px;
  }
  .Form55 {
    font-size: 20px;
  }
  .fonter {
    padding: 7px 10px;
    font-weight: 500;
    font-size: 14px;
    margin: 0;
  }
}
@media screen and (min-width: 480px) and (max-width: 640px) {
  .personal_Info_fields {
    padding: 10px 0px;
  }

 
}
@media screen and (min-width: 640px) and (max-width: 800px) {

  .personal_Info_fields {
    padding: 10px 0px;
  }

}

/* @media only screen and (min-width: 200px) and (max-width: 320px) {

}
@media screen and (min-width: 320px) and (max-width: 480px) {

}
@media screen and (min-width: 480px) and (max-width: 640px) {

}
@media screen and (min-width: 640px) and (max-width: 980px) {

}
@media screen and (min-width: 980px) and (max-width: 1266px) {

}
@media screen and (min-width: 1266px) and (max-width: 1346px) {

}
@media screen and (min-width: 1346px) and (max-width: 1980px) {

} */
