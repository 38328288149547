.uploadDocument_fields {
  width: 100%;
  height: 100%;
  background-color: #d8d5d566;
  padding: 20px;
}
.uploadDocument_Info {
  width: 100%;
  height: 100%;
}
.UploadDocument_Label {
  width: 30%;
  text-align: start;
  padding-right: 10px;
}
.uploadDocument_label_input {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}
.uploadDocument_input {
  width: 40%;
  padding: 0 13%;
}
.uploadDocument_Error {
  width: 30%;
}

.submit-btn {
  padding: 5px 15px;
  border: none;
  outline: none;
  background-color: lightgrey;
  color: #284caf;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s;
  cursor: pointer;
  margin: 10px;
  border-radius: 10px;
}

.submit-btn:hover {
  background-color: #5666a1;
  color: white;
}
.uploadDocument_Error_Mobile {
  display: none;
}

@media only screen and (min-width: 200px) and (max-width: 320px) {
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .uploadDocument_Error {
    display: none;
  }
  .uploadDocument_input {
    width: 50%;
  }
  .uploadDocument_Error_Mobile {
    margin-bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .uploadDocument_label_input {
    margin-bottom: 0px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 640px) {
}

@media only screen and (min-width: 640px) and (max-width: 980px) {
}
