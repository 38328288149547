.view-edit-btn,
.save-btn {
  padding: 10px 10px;
  border: none;
  outline: none;
  background-color: #fc4a1a;
  color: white;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: 0.3s;
    margin: 0px 5px;
  cursor: pointer;
  border-radius: 10px;
}

.save-btn-disable {
  padding: 10px 10px;
    border: none;
    outline: none;
    background-color: #d7d7d7;
    color: white;
    font-size: 16px;
    font-weight: 400;
    transition: 0.3s;
    /* margin: 10px; */
    border-radius: 10px;
}

.view-edit-btn {
  background-color: rgb(175, 167, 167);
}
.save-btn {
  margin-top: 30px;
}
.view-edit-btn:hover {
  background-color: rgb(175, 167, 167);
}

.view-edit-btn.active-btn,
.save-btn:hover {
  background-color: #fc4a1a;
  color: white;
}
.employeeId_Text {
  font-size: large;
  margin: 0;
  padding-left: 8px;
}
.employeeIdea_notyetgenerated_text {
  font-weight: 500;
  padding-left: 6px;
}
.ifnotGenerated_btns {
  width: 100%;
  display: flex;
  align-items: center;
}
.ifnotGenerated {
  width: 50%;
}

/* ::-webkit-calendar-picker-indicator {
  filter: invert(1);
} */

@media screen and (max-width: 320px) {
}
@media screen and (min-width: 320px) and (max-width: 480px) {
}
@media screen and (min-width: 480px) and (max-width: 640px) {
}
@media screen and (min-width: 640px) and (max-width: 980px) {
}
@media screen and (min-width: 980px) and (max-width: 1266px) {
}
@media screen and (min-width: 1980px) and (max-width: 2700px) {
}
