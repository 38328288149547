.HRloginpage {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.background_img {
  /* right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%; */
  z-index: -1;

  width: 100%;
  /* height: auto; */
  /* //karthik*/
  height: 100%;
  position: absolute;
}
.Caprus_Image_EmployeeBtn {
  width: 100%;
  display: flex;
}
.EmployeeBtn {
  width: 50%;
  display: flex;
  padding-right: 10px;
  justify-content: flex-end;
}
.Caprus_Image {
  width: 50%;
}
.Admin {
  position: absolute;
  padding: 10px 20px;
  border-radius: 2px solid black; 
  background: linear-gradient(to left, #f47f62 50%, #f3451a 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: white;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-family: sans-serif;
  top: 6%;
  border: 0;
  border-radius: 23px;
}
.Admin:hover {
  background-position: left bottom;
}
.inside_card {
  box-shadow: 3px 5px 7px 0px rgb(197 179 179 / 94%),
    1px 0px 20px 0px rgb(6 0 0 / 19%);
  width: 300px;
  padding: 20px;
  text-align: center;
  border-radius: 20px;
  background-color: WHITE;
  transition: ease-in-out 0.5s;
  /* //karthik */
  opacity: 0.9;
}
.inside_card:hover {
  transform: scale(1.1);
  transition: 0.5s;
  transition-timing-function: ease-in-out;
}
.UserPage_Card {
  width: 100%;
  height: 100%;
}
.userPage_card_Inside {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 28%;
}
.Email_input_text {
  text-align: center;
  height: 37px;
  cursor: pointer;
  box-shadow: 0px 5px 14px rgb(0 0 0 / 50%);
  text-shadow: 90px;
  position: relative;
  width: 255px;
  border-radius: 23px;
  color: white;
  border: 1px solid #ececec;
  background-color: #0e55a1;
  outline: none;
}
.Email_input_text::placeholder {
  color: white;
}

.Otp_Submit_Btn {
  cursor: pointer;
  border-radius: 10px;
  height: 36px;
  text-align: center;
  width: 100px;
  background-color: #f3451a;
  color: white;
  transition: 0.6s;
  border: none;
}
.Submit_btn_userpage_admin_otp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.Submit_btn_userpage_admin_otp .pi {
  font-size: 12px;
  padding-left: 9px;
}
.Otp_Submit_Btn:hover {
  transition: ease-in-out 0.3s;
  background-color: #cccccc;
  color: #284caf;
}

.CaprusImage {
  top: 5%;
  left: 3%;
  width: 200px;
  cursor: pointer;
  position: absolute;
}
.Emailtext {
      font-size: 14px;
        font-weight: 600;
  color: #284caf;
}
.Model_ .p-button {
  background-color: white !important;
}
.Model_ .p-button-label {
  color: #284caf !important;
}
.spaceBetween_otp_Submit {
  margin-top: 10px;
}
/* otpcss */
.OTP_inputField {
  text-align: center;
  margin: 10px 10px;
  outline: none;
  padding: 15px;
  cursor: pointer;
  width: 47px;
  color: white;
  height: 48px;
  padding: 0px;
  border-radius: 13px;
  background-color: #0e55a1;
  border: none;
}
.Otpinput-submit {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otpinput {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 13px;
}
.ResendOtp,
.Email_Change {
  color: #010101;
  margin-top: 10px;
  cursor: pointer;
}
.ResendOtp:hover,
.Email_Change:hover {
  color: red;
  cursor: pointer;
}
.Otp_Modifiactions_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.Didnt_recieve_OTP {
  margin-bottom: 10px;
}
.Otpinput-submit {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.otpinput {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 13px;
}
.Otpsubmit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

/* otpcss */
@media screen and (min-width: 200px) and (max-width: 320px) {
  .inside_card {
    width: 260px;
  }
  .userPage_card_Inside {
    width: 100%;
  }
  .Email_input_text {
    width: 200px;
  }
  .CaprusImage {
    width: 160px;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .inside_card {
    width: 344px;
  }
  .userPage_card_Inside {
    width: 100%;
  }
}
